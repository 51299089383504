import React from "react"
import gradIcon from "../../static/grad-cap-icon-yay-afe8a.png"
import certificate from "../../static/RDSU3G2P.pdf"
import certificateImage from "../../static/RDSU3G2P.svg"

export default () => (
  <div className="" style={{ transform: "translate(0px, 0px)" }}>
    <span style={{ opacity: "0.9" }}></span>
    <header className="graduated-page--color-splash--319LF">
      <div
        role="button"
        tabIndex="0"
        className="graduated-page--container--1HEdt"
        style={{ outline: "none" }}
      >
        <div className="graduated-page--details--2XcPC">
          <img
            alt="grad-cap"
            src={gradIcon}
            className="graduated-page--icon-cap--1hlci"
          />
          <h1 className="graduated-page--title--3HQMX">
            Udacity Nanodegree Program Graduate
          </h1>
          <div className="graduated-page--download-container--1rbD-">
            <a
              href={certificate}
              download
              className="graduated-page--download--SRz1U"
            >
              <button
                type="button"
                className="index--default--33CkP index--primary--3XfTi index--_btn--1QPC1 graduated-page--view-cert-button--3XFfU index--standard--mpCCh"
                style={{ cursor: "pointer" }}
              >
                <i className="vds-icon" role="img" aria-hidden="true">
                  <svg viewBox="0 0 32 32" width="1em" height="1em">
                    <path
                      d="M17 18.586l3.293-3.293a1 1 0 0 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L15 18.586V7a1 1 0 0 1 2 0v11.586zM7 26a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2H7z"
                      fillRule="nonzero"
                    ></path>
                  </svg>
                </i>
                Download Certificate
              </button>
            </a>
          </div>
          <div className="graduated-page--link-container--3yVQz">
            <p
              id="link-copy"
              data-clipboard-text="https://udacity.xyz/confirm/RDSU3G2P"
              className="graduated-page--shareable-link--2u34t"
            >
              https://udacity.xyz/confirm/RDSU3G2P
            </p>
          </div>
        </div>
        <div className="graduated-page--certificate--2D6ya">
          <img
            src={certificateImage}
            alt="preview of your graduation certificate"
          />
          <div className="graduated-page--ndop-link--3koI_">
            <a
              href="https://www.udacity.com/course/machine-learning-engineer-nanodegree--nd009t"
              target="_blank"
            >
              Learn more about this Nanodegree program
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
)
