import React, { Fragment } from "react"
import gradIcon from "../../static/grad-cap-icon-yay-afe8a.png"
import logo from "../../static/logo-udacity-812f6.svg"

export default () => (
  <Fragment>
    <div className="header--marketing-header--JZaF_">
      <div className="header--marketing-header-nav--1334O">
        <a href="https://udacity.com" className="header--home-link--2_6CO">
          <img
            src={logo}
            alt="Udacity"
            className="header--header-logo-light--3dapB"
          />
        </a>
        <div className="nav--nav-horizontal--1NZV0">
          <div className="nav-links--nav-contain--231wT">
            <div className="nav-link--link--3V5Wk">
              <a
                href="https://www.udacity.com/pathfinder"
                className="nav-link--link-text--2XP0C"
              >
                explore
              </a>
            </div>
            <div className="nav-link--link--3V5Wk">
              <a
                href="https://www.udacity.com/nanodegree"
                className="nav-link--link-text--2XP0C"
              >
                nanodegree
              </a>
            </div>
            <div className="nav-link--link--3V5Wk nav-link--border-right--1Ek5c">
              <a
                href="https://www.udacity.com/courses/all"
                className="nav-link--link-text--2XP0C"
              >
                catalog
              </a>
            </div>
            <div className="nav-link--link--3V5Wk nav-link--border-left--28hnL">
              <a
                href="http://auth.udacity.com/sign-in"
                className="nav-link--link-text--2XP0C"
              >
                sign in
              </a>
            </div>
            <button
              type="button"
              className="index--primary--3XfTi index--_btn--1QPC1 nav-links--button--aUxGf nav-links--signedOut--3MOyo index--standard--mpCCh"
              style={{ cursor: "pointer" }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)
