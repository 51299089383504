import React, { Fragment } from "react"
import Header from "../../components/header"
import Body from "../../components/body"

import "../../styles/global.css"
export default () => (
  <Fragment>
    <Header />
    <Body />
  </Fragment>
)
